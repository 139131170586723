import { useLocation } from "react-router-dom";
import React, { useEffect, useState, Suspense } from "react";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import Offline from "./Offline";
const Ayat = React.lazy(() => import("../components/Ayat"));

export default function Surahdetail(props) {
  const perPage = props.page;
  const [noOfElement, setNoOfElement] = useState(perPage);
  const [exist, setexist] = useState(true);

  const [loading, setLoading] = useState(true);
  const [offline, setOffline] = useState(false);

  const [surah, setSurah] = useState([]);
  const [tafsir, setTafsir] = useState(true);
  const [terjemah, setTerjemah] = useState(true);

  const slice = surah.slice(0, noOfElement);

  const location = useLocation();
  const params = location.state;

  const loadMore = () => {
    setNoOfElement(noOfElement + perPage);
  };

  function handleChangeTafsir() {
    setTafsir(!tafsir);
  }

  function handleChangeTerjemah() {
    setTerjemah(!terjemah);
  }

  function handleback(e) {
    e.preventDefault();
    window.history.back();
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    async function getSurah() {
      try {
        const id = parseInt(params.surah.surah_id);
        const request = await fetch(`assets/data/verses/${id}.json`);

        const result = await request.json();
        setSurah(result);
        setOffline(false);
      } catch (error) {
        setOffline(true);
      }

      setLoading(false);
    }
    getSurah();

    document.title = `e-Qur'an | Surah ${
      params.surah.surah_name && params.surah.surah_name.replace(/-/g, " ")
    } - ${params.surah.surah_name_arabic}`;
  }, [params]);

  useEffect(() => {
    surah.length.length > 0 && surah.length <= noOfElement && setexist(false);
    const scrollFetch = () => {
      surah.length === slice.length && setexist(false);
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        setNoOfElement(noOfElement + perPage);
        console.log("scr");
      }
    };
    window.addEventListener("scroll", scrollFetch, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", scrollFetch);
    };
  }, [surah, exist, noOfElement, perPage, slice.length]);

  return (
    <>
      <div className="navbar  shadow-lg bg-neutral text-neutral-content fixed w-full z-50">
        <div className="flex-none">
          <button className="btn btn-square btn-ghost" onClick={handleback}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-6 h-6 stroke-current text-success"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              ></path>
            </svg>
          </button>
        </div>
        <div className="flex-1 px-1">
          <span className="text-lg font-semibold ">
            {!loading ? params.surah.surah_name : "Memuat data"}
          </span>
        </div>

        <div className="dropdown dropdown-end">
          <div tabIndex="0" className="btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <ul
            tabIndex="0"
            className="p-2 menu dropdown-content text-neutral-content bg-neutral mt-40 lg:mt-[10.2rem] divide-y  w-48"
          >
            <li>
              <label
                htmlFor="tafsirToogle"
                className="p-2 flex content-between place-content-between"
              >
                <input
                  id="tafsirToogle"
                  type="checkbox"
                  checked={tafsir}
                  onChange={handleChangeTafsir}
                  className="toggle toggle-accent"
                />
                <span className="ml-2">Tafsir</span>
              </label>
            </li>
            <li>
              <label
                htmlFor="terjemahToggle"
                className="p-2 flex content-between place-content-between"
              >
                <input
                  id="terjemahToggle"
                  type="checkbox"
                  checked={terjemah}
                  onChange={handleChangeTerjemah}
                  className="toggle toggle-accent"
                />
                <span className="ml-2">Terjemah</span>
              </label>
            </li>
          </ul>
        </div>
      </div>

      {offline ? (
        <Offline></Offline>
      ) : (
        <>
          {loading ? (
            <>
              <div className="pt-12"></div>
              <Loader />
            </>
          ) : (
            params.surah && (
              <>
                <ul className="divide-y divide-gray-200 dark:divide-gray-700 pt-14 md:mx-6">
                  {params.surah.surah_id === 1 ||
                  params.surah.surah_id === 9 ? (
                    ""
                  ) : (
                    <li className="py-3 sm:py-4">
                      <div className="flex  justify-center-center ">
                        <div className="flex-1 min-w-0 px-4 py-2 md:px-6 lg:px-12 flex-col space-y-3">
                          <p className="font-LPMQ text-center leading-10 lg:leading-[3.5rem]  text-gray-900 text-xl lg:text-2xl dark:text-white">
                            {"بِسْمِ اللّٰهِ الرَّحْمٰنِ الرَّحِيْمِ"}
                          </p>
                        </div>
                      </div>
                    </li>
                  )}

                  {slice.map((ayat) => {
                    return (
                      <Suspense
                        key={ayat.verse_id}
                        fallback={<Loader></Loader>}
                      >
                        <Ayat
                          tafsir={tafsir}
                          terjemah={terjemah}
                          key={ayat.verse_id}
                          ayat={ayat}
                          surah={{
                            surah_name: params.surah.surah_name,
                            surah_name_arabic: params.surah.surah_name_arabic,
                            surah_name_bahasa: params.surah.surah_name_bahasa,
                            surah_verse_count: params.surah.surah_verse_count,
                          }}
                        />
                      </Suspense>
                    );
                  })}
                </ul>
                {exist && (
                  <div className=" py-1" onClick={loadMore}>
                    <div className="divider">
                      <p>Klik untuk ayat selanjutnya</p>
                    </div>
                  </div>
                )}
                {!exist && <Footer></Footer>}
              </>
            )
          )}
        </>
      )}
    </>
  );
}
