function Footer() {
  return (
    <nav className="navbar mt-5 shadow-lg bg-neutral text-neutral-content md:px-6 lg:px-10">
      <div className="px-2 ml-2 md:ml-4 lg:ml-6 navbar-start">
        <a
          href="http://nurhadi.net"
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg md:font-semibold"
        >
          nurhadi.net
        </a>
      </div>
      <div className="navbar-end  mr-2 md:mr-6 lg:mr-8 mx-auto">
        <div className="flex items-center space-x-1 md:space-x-2">
          <a
            href="https://linkedin.com/in/nurhadi"
            className="flex content-center p-3"
            aria-label="Ke profile linkedin"
          >
            <svg
              className="h-6 w-6 text-inherit"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
              <rect x="2" y="9" width="4" height="12" />
              <circle cx="4" cy="4" r="2" />
            </svg>
          </a>
          <a
            href="https://github.com/nurhadinubi"
            className="flex content-center p-3"
            aria-label="Ke profile Github"
          >
            <svg
              className="h-6 w-6 text-inherit"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
            </svg>
          </a>
          <a
            href="https://instagram.com/nurhadinubi"
            className="flex content-center p-3"
            aria-label="Ke profile Instagram"
          >
            <svg
              className="h-6 w-6 text-inherit"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
              <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
            </svg>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Footer;
