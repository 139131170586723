export default function Offline() {
  return (
    <>
      <div className="hero min-h-screen">
        <div className="text-center hero-content">
          <div className="max-w-md flex flex-col">
            <div className="mb-4 mx-auto ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-32 w-32 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                />
              </svg>
            </div>
            <h1 className="mb-5 text-5xl font-bold">Offline</h1>
            <h2 className="mb-5 text-2xl ">Sepertinya anda sedang Offline</h2>
            <p>Periksa kembali jaringan anda</p>
          </div>
        </div>
      </div>
    </>
  );
}
