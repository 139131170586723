import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";

function NotFound() {
  return (
    <>
      <Navbar />
      <div className="hero min-h-screen bg-base-200">
        <div className="text-center hero-content">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold">Not Found</h1>
            <h2 className="mb-5 text-2xl ">
              Halaman yang anda cari tidak ditemukan
            </h2>
            <Link to={"/"}>
              <button className="btn btn-primary">Kembali</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
