import SurahList from "./SurahList";
import SurahDetail from "./SurahDetail";
import Tafsir from "./Tafsir";
import About from "./About";

function Home(props) {
  const page = props.page.toLowerCase();

  return (
    <>
      {page === "home" && <SurahList page={30}></SurahList>}
      {page === "surah" && <SurahDetail page={10}></SurahDetail>}
      {page === "tafsir" && <Tafsir></Tafsir>}
      {page === "about" && <About></About>}
    </>
  );
}

export default Home;
