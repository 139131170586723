import React, { useEffect, useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import Offline from "./Offline";
const SurahItem = React.lazy(() => import("../components/SurahItem"));

export default function SurahList(props) {
  const perPage = props.page;
  const [noOfElement, setNoOfElement] = useState(perPage);
  const [exist, setexist] = useState(true);

  const [offline, setOffline] = useState(false);

  const [surahList, setSurahList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  function handleClick(surah) {
    navigate("/", {
      state: {
        page: "surah",
        surah,
      },
    });
  }

  const slice = surahList.slice(0, noOfElement);

  const loadMore = () => {
    setNoOfElement(noOfElement + perPage);
  };

  useEffect(() => {
    async function getSurahList() {
      try {
        const req = await fetch("/assets/data/surah.json");
        const result = await req.json();
        setSurahList(result);
        setOffline(false);
      } catch (error) {
        setOffline(true);
      }

      setLoading(false);
    }
    getSurahList();
  }, []);

  useEffect(() => {
    surahList.length.length > 0 &&
      surahList.length <= noOfElement &&
      setexist(false);

    const scrollFetch = () => {
      surahList.length === slice.length && setexist(false);
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        setNoOfElement(noOfElement + perPage);
      }
    };
    window.addEventListener("scroll", scrollFetch, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", scrollFetch);
    };
  }, [surahList, exist, noOfElement, perPage, slice.length]);

  return (
    <>
      <Navbar />
      {loading ? (
        <Loader></Loader>
      ) : offline ? (
        <Offline></Offline>
      ) : (
        <>
          <Suspense fallback={<Loader></Loader>}>
            <div className="px-2 md:px-12 md:mx-6 md:my-5 flex flex-wrap ">
              {slice.map((surah) => {
                return (
                  <button
                    className="w-full md:w-1/2 lg:w-1/3 m-0 mx-auto xl:1/4"
                    key={surah.surah_id}
                    onClick={handleClick.bind(this, surah)}
                  >
                    <SurahItem key={surah.surah_id} surah={surah} />
                  </button>
                );
              })}
            </div>
          </Suspense>
          {exist && (
            <div className=" py-1" onClick={loadMore}>
              <div className="divider">
                <p>Klik untuk ayat selanjutnya</p>
              </div>
            </div>
          )}
        </>
      )}
      {!exist && <Footer></Footer>}
    </>
  );
}
