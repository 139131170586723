import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import NotFound from "./NotFound";
import Footer from "../components/Footer";

function Tafsir() {
  const location = useLocation();
  const data = location.state.data;

  function handleback(e) {
    e.preventDefault();
    window.history.back();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `e-Qur'an | Surah ${
      data.surah && data.surah.surah_name.replace(/-/g, " ")
    } Ayat ${data.ayat && data.ayat.verse_number}`;
  }, [location.pathname, data]);

  return (
    <>
      {!data ? (
        <NotFound></NotFound>
      ) : (
        <>
          <div className="navbar  shadow-lg bg-neutral text-neutral-content fixed w-full z-50">
            <div className="flex-none">
              <button className="btn btn-square btn-ghost" onClick={handleback}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-6 h-6 stroke-current text-success"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="flex-1 px-1">
              <span className="text-lg font-semibold ">
                {data.surah.surah_name.replace(/-/g, " ")} :{" "}
                {data.ayat.verse_number}
              </span>
            </div>

            <div>
              <span className="text-2xl  mr-2 font-LPMQ">
                {data.surah.surah_name_arabic}
              </span>
            </div>
          </div>
          <div className="pt-20 mx-2 md:mx-6 relative">
            <div className="card shadow md:mx-6 ">
              <div className="card-body">
                <h2 className="card-title font-LPMQ text-right text-2xl leading-[3rem]">
                  {data.ayat.verse_arabic}
                </h2>
                <p className="mt-6 text-lg text-neutral text-justify">
                  {`${data.ayat.verse_number}. ${data.ayat.verse_bahasa}`}
                </p>
              </div>
            </div>

            <div className="md:mx-6 ">
              <audio
                className="w-full mt-6"
                src={data.ayat.verse_audio}
                id="audio"
                controls
              ></audio>
            </div>

            <ul className="mt-8 mx-2 md:mx-6 divide-y divide-neutral">
              <li className="py-4">
                <div className="card ">
                  <h2 className="card-title  text-2xl">Tafsir Pendek</h2>
                  <p className="mt-6 text-lg text-neutral text-justify">
                    {data.ayat.tafsir.short}
                  </p>
                </div>
              </li>
              <li className="py-4">
                <div className="">
                  <h2 className="card-title  text-2xl">Tafsir Panjang</h2>
                  <p className="mt-6 text-lg text-neutral text-justify">
                    {data.ayat.tafsir.long}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </>
      )}

      <Footer></Footer>
    </>
  );
}

export default Tafsir;
