import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function About() {
  useEffect(() => {
    document.title = "e-Qur'an | Tentang Aplikasi";
  });
  return (
    <>
      <Navbar />
      <div className="hero min-h-[calc(100vh-10rem)] bg-base-200">
        <div className="text-center hero-content">
          <div className="max-w-md">
            <h1 className=" text-3xl font-bold">e-Qur'an</h1>
            <p>v1.0.0 alpha</p>
            <div className="mt-5">
              <strong>e-Qur'an</strong> merupakan applikasi berbasis web
              dibangun menggunakan React JS yang berisi konten alquran digital.
              Data dari konten ini dimbil dari repositori{" "}
              <a
                href="https://github.com/subekti404dev/quran-kemenag"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                {" "}
                quran-kemenag on github,
              </a>{" "}
              yang bersumber dari{" "}
              <a
                href="https://quran.kemenag.go.id"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                https://quran.kemenag.go.id.
              </a>
              <p className="font-semibold">
                Jika anda mnemukan kesalahan, segera hubungi kami{" "}
                <a
                  href="https://nurhadi.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  disini
                </a>
              </p>
            </div>
            <div className="mt-5">
              <h2 className="text-2xl">Credits</h2>
              <ul>
                <li>
                  {" "}
                  <a
                    href="https://github.com/subekti404dev/quran-kemenag"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {" "}
                    quran-kemenag on github
                  </a>
                </li>

                <li>
                  {" "}
                  <a
                    href="https://daisyui.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {" "}
                    Tailwind CSS
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="https://daisyui.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {" "}
                    daisyUI
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href="https://reactjs.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {" "}
                    React
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default About;
