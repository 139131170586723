import { Link, useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  function handleAbout() {
    navigate(`/`, {
      state: {
        page: "about",
      },
    });
  }

  return (
    <nav className="navbar mb-2 shadow-lg bg-neutral text-neutral-content md:px-6 lg:px-10">
      <Link to="/" className="px-2 mx-2 navbar-start">
        <span className="text-lg font-bold">e-Qur'an</span>
      </Link>
      <div className="navbar-end mx-auto">
        <button className="btn" onClick={handleAbout}>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p className="ml-2">About</p>
          </div>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
