import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";

import NotFound from "./pages/NotFound";

export default function App() {
  const location = useLocation();

  let page = "";
  page = location.state === null ? "home" : location.state.page;

  return (
    <>
      <Routes>
        <Route path="/" element={<Home page={page} />}></Route>
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
    </>
  );
}
