function Loader() {
  return (
    <>
      <div className="my-3 md:my-5 min-h-[calc(100vh-5rem)]">
        <div className="border border-gray-200 p-4 col-span-3">
          <div className="animate-pulse space-y-8">
            <div className="flex space-x-4">
              <div className="bg-gray-200 h-10 w-10 rounded-full"></div>
              <div className="space-y-2 flex-1">
                <div className="h-6 bg-gray-200 w-2/2"></div>
                <div className="h-6 bg-gray-200 w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-gray-200 p-4 col-span-3">
          <div className="animate-pulse space-y-8">
            <div className="flex space-x-4">
              <div className="bg-gray-200 h-10 w-10 rounded-full"></div>
              <div className="space-y-2 flex-1">
                <div className="h-6 bg-gray-200 w-2/2"></div>
                <div className="h-6 bg-gray-200 w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-gray-200 p-4 col-span-3">
          <div className="animate-pulse space-y-8">
            <div className="flex space-x-4">
              <div className="bg-gray-200 h-10 w-10 rounded-full"></div>
              <div className="space-y-2 flex-1">
                <div className="h-6 bg-gray-200 w-2/2"></div>
                <div className="h-6 bg-gray-200 w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-gray-200 p-4 col-span-3">
          <div className="animate-pulse space-y-8">
            <div className="flex space-x-4">
              <div className="bg-gray-200 h-10 w-10 rounded-full"></div>
              <div className="space-y-2 flex-1">
                <div className="h-6 bg-gray-200 w-2/2"></div>
                <div className="h-6 bg-gray-200 w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-gray-200 p-4 col-span-3">
          <div className="animate-pulse space-y-8">
            <div className="flex space-x-4">
              <div className="bg-gray-200 h-10 w-10 rounded-full"></div>
              <div className="space-y-2 flex-1">
                <div className="h-6 bg-gray-200 w-2/2"></div>
                <div className="h-6 bg-gray-200 w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-gray-200 p-4 col-span-3">
          <div className="animate-pulse space-y-8">
            <div className="flex space-x-4">
              <div className="bg-gray-200 h-10 w-10 rounded-full"></div>
              <div className="space-y-2 flex-1">
                <div className="h-6 bg-gray-200 w-2/2"></div>
                <div className="h-6 bg-gray-200 w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="border border-gray-200 p-4 col-span-3">
          <div className="animate-pulse space-y-8">
            <div className="flex space-x-4">
              <div className="bg-gray-200 h-10 w-10 rounded-full"></div>
              <div className="space-y-2 flex-1">
                <div className="h-6 bg-gray-200 w-2/2"></div>
                <div className="h-6 bg-gray-200 w-2/3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Loader;
